import { FaHeart } from "react-icons/fa";

import Car from "../../Components/Carousel/Carousel";
import Toc from "../../Components/TableOfContents/TableOfContents";
import ExampleButton from "../../Components/ExampleButton/ExampleButton";

const components = [
	{
		name: "carousel",
		created: "July 2021",
		project: "Personal Portfolio",
		desc: "The Carousel component was created to showcase my skills, however upon creating the component, I had other ideas for that section. The component is however still useful and works well, is responsive, and allows for a couple of different configuration options. \n Any valid JSX can be rendered in each Carousel Item.",
		code: "https://github.com/luca2849/lucapassariello.co.uk/tree/main/client/src/Components/Carousel",
		props: [
			{
				name: "width",
				desc: "Defines the width of the carousel component.",
				type: "String/Integer",
				possible_values: ["75%", "'500px'", "500", "'500'"],
				optional: true,
				default: "500",
			},
			{
				name: "duration",
				desc: "Defines the duration of the Carousel slide change animation in milliseconds.",
				type: "Integer",
				possible_values: ["300", "1000"],
				optional: true,
				default: "300",
			},
		],
		how: "The main challenge when creating the Carousel was how to hide the non-displayed slides without affecting the layout of the rest of the page. The solution found was to render all slides (items) as seperate flex items inside a relativley positioned container, with a fixed (or percentage-based) width. \n When changing slide, via the arrows or through the slide selection buttons, the distance to offset the container by is calculated using the width of the Carousel, and the entire Carousel is then transformed by that new offset, getting the user to the correct page. \n Another key feature of this Carousel is its responsiveness. I wanted the user to be able to set a variable width for the Carousel, which they can do via the width prop. This caused some issues when resizing the window, which was solved with an event listener, checking for a change in window size, and recalculating the offsets when this occurs. If a width which is not recognised is passed as a prop, the default size of 500px is used.",
		examples: [
			{
				name: "Basic",
				desc: "This is a basic implementation of the carousel component, which uses a default width of 500px.",
				string: `<Carousel>
    <Carousel.Item>
        <h3>Page 1</h3>
    </Carousel.Item>
    <Carousel.Item>
        <h3>Page 2</h3>
    </Carousel.Item>
    <Carousel.Item>
        <h3>Page 3</h3>
    </Carousel.Item>
    <Carousel.Item>
        <h3>Page 4</h3>
    </Carousel.Item>
</Carousel>`,
				component: (
					<Car>
						<Car.Item>
							<h3>Page 1</h3>
						</Car.Item>
						<Car.Item>
							<h3>Page 2</h3>
						</Car.Item>
						<Car.Item>
							<h3>Page 3</h3>
						</Car.Item>
						<Car.Item>
							<h3>Page 4</h3>
						</Car.Item>
					</Car>
				),
			},
			{
				name: "Variable Width",
				desc: "This is an implementation using a variable percentage width.",
				string: `<Carousel width="50%">
    <Carousel.Item>
        <h3>Page 1</h3>
    </Carousel.Item>
    <Carousel.Item>
        <h3>Page 2</h3>
    </Carousel.Item>
    <Carousel.Item>
        <h3>Page 3</h3>
    </Carousel.Item>
    <Carousel.Item>
        <h3>Page 4</h3>
    </Carousel.Item>
</Carousel>`,
				component: (
					<Car width="50%">
						<Car.Item>
							<h3>Page 1</h3>
						</Car.Item>
						<Car.Item>
							<h3>Page 2</h3>
						</Car.Item>
						<Car.Item>
							<h3>Page 3</h3>
						</Car.Item>
						<Car.Item>
							<h3>Page 4</h3>
						</Car.Item>
					</Car>
				),
			},
		],
	},
	{
		name: "Table of Contents",
		created: "August 2021",
		project: "Personal Portfolio",
		desc: "The Table of Contents component was created to use on this page, so navigation between documentation sections would be easier. Essentially, it is just a different way of formatting a HTML list, with extra styling. \n Because this component is a practical extension of HTML unordered lists, nested lists can be used to show different sections to the table of contents, allowing for more possible use cases.",
		code: "https://github.com/luca2849/lucapassariello.co.uk/tree/main/client/src/Components/TableOfContents",
		props: [
			{
				name: "bullets",
				desc: "Determines whether bullet points/numbers are shown in the ToC.",
				type: "Boolean",
				possible_values: ["true", "false"],
				optional: true,
				default: "false",
			},
			{
				name: "numbers",
				desc: "Determines whether numbers are used instead of bullet points.",
				type: "Boolean",
				possible_values: ["true", "false"],
				optional: true,
				default: "false",
			},
		],
		how: "The table of contents (TOC) component is rather basic, as it is essentially an implementation of HTML lists, with additional styling and easy customisation options. \n Using the same React component dot notation method as the Carousel, the TOC component can be used in combination with TOC.List and TOC.Item to provide the full usage of regular HTML lists, with props to control the display and styling of the lists. \n The code for this component, which is available via the button near the top of this section, is quite self-explanitory, and an example implementation with React refs for scrolling, used on this page, is available in the repository for this site.",
		examples: [
			{
				name: "Basic",
				desc: "This is a basic implementation of the table of contents component.",
				string: `<Toc>
  <Toc.List>
    <Toc.Item>Item 1</Toc.Item>
    <Toc.Item>Item 2</Toc.Item>
    <Toc.Item>Sublist Header</Toc.Item>
    <Toc.List>
      <Toc.Item>Sublist Item</Toc.Item>
    </Toc.List>
  </Toc.List>
</Toc>`,
				component: (
					<Toc>
						<Toc.List>
							<Toc.Item>Item 1</Toc.Item>
							<Toc.Item>Item 2</Toc.Item>
							<Toc.Item>Sublist Header</Toc.Item>
							<Toc.List>
								<Toc.Item>Sublist Item</Toc.Item>
							</Toc.List>
						</Toc.List>
					</Toc>
				),
			},
			{
				name: "Numbered Markers",
				desc: "This implementation shows how to allow numbers to be used as bullet point markers.",
				string: `<Toc bullets={true} numbers={true}>
  <Toc.List>
    <Toc.Item>Item 1</Toc.Item>
    <Toc.Item>Item 2</Toc.Item>
    <Toc.Item>Sublist Header</Toc.Item>
    <Toc.List>
      <Toc.Item>Sublist Item</Toc.Item>
    </Toc.List>
  </Toc.List>
</Toc>`,
				component: (
					<Toc bullets={true} numbers={true}>
						<Toc.List>
							<Toc.Item>Item 1</Toc.Item>
							<Toc.Item>Item 2</Toc.Item>
							<Toc.Item>Sublist Header</Toc.Item>
							<Toc.List>
								<Toc.Item>Sublist Item</Toc.Item>
							</Toc.List>
						</Toc.List>
					</Toc>
				),
			},
		],
	},
	{
		name: "Button",
		created: "June 2022",
		project: "DevChallenges.IO",
		desc: "In June of 2022, I came across a website which contained lots of challenges for aspiring developers, called devChallenges.io. One of the front-end development tasks was to create a Buttons component, which satisfied all user stories provided. \n I created a basic deployment of this feature, which can be found at https://buttons.fe.lucapassariello.co.uk.",
		code: "https://github.com/luca2849/lucapassariello.co.uk/tree/main/client/src/Components/ExampleButton",
		props: [
			{
				name: "variant",
				desc: "Determines what variant the button is.",
				type: "String",
				possible_values: ["outline", "text"],
				optional: true,
				default: "None",
			},
			{
				name: "disabled",
				desc: "Determines whether a button is disabled.",
				type: "Boolean",
				possible_values: ["true", "false"],
				optional: true,
				default: "false",
			},
			{
				name: "startIcon",
				desc: "An icon to be displayed before the button text.",
				type: "Component",
				possible_values: ["<i></i>", "<FaHeart />"],
				optional: true,
				default: "None",
			},
			{
				name: "endIcon",
				desc: "An icon to be displayed after the button text.",
				type: "Component",
				possible_values: ["<i></i>", "<FaHeart />"],
				optional: true,
				default: "None",
			},
			{
				name: "color",
				desc: "Determines the colour scheme of the button.",
				type: "String",
				possible_values: ["primary", "default", "secondary", "danger"],
				optional: true,
				default: "default",
			},
			{
				name: "size",
				desc: "Determines the font size and padding of the button.",
				type: "String",
				possible_values: ["sm", "md", "lg"],
				optional: true,
				default: "sm",
			},
		],
		how: "This component is not too complex, the hardest part was working out which props should take precedence over others in terms of styling, for example, what should happen if both a variant and a color are provided. \n This was a fairly quick component to build, and satisfied the provided user stories well. This was my first time experimenting with a component which takes in lots of props, so I created a utility function for sanitising the props passed into the component, and replacing any missing ones with defaults.",
		examples: [
			{
				name: "Default",
				desc: "This is the default implementation of the Button component.",
				string: `<Button>Default</Button>`,
				component: <ExampleButton>Default</ExampleButton>,
			},
			{
				name: "Outlined",
				desc: "This version consists of a button with a solid outline, and an opaque hover-able background.",
				string: `<Button variant="outline">Default</Button>`,
				component: (
					<ExampleButton variant="outline">Default</ExampleButton>
				),
			},
			{
				name: "Resizing",
				desc: "This version shows the three sizes made available to users. They are also customisable with custom CSS.",
				string: `<Button size="lg">Default</Button>`,
				component: <ExampleButton size="lg">Default</ExampleButton>,
			},
			{
				name: "Disabled",
				desc: "This version shows the three sizes made available to users. They are also customisable with custom CSS.",
				string: `<Button disabled={true}>Default</Button>`,
				component: (
					<ExampleButton disabled={true}>Default</ExampleButton>
				),
			},
			{
				name: "Coloured",
				desc: "There are also four possible colour schemes to pick from, each with their own custom hover colours.",
				string: `<Button color="danger">Default</Button>`,
				component: (
					<ExampleButton color="danger">Default</ExampleButton>
				),
			},
			{
				name: "Coloured Disabled",
				desc: "There are also four possible colour schemes to pick from, each with their own custom hover colours.",
				string: `<Button color="secondary" disabled={true}>Default</Button>`,
				component: (
					<ExampleButton color="secondary" disabled>
						Default
					</ExampleButton>
				),
			},
			{
				name: "Button with Start Icon",
				desc: "There are also four possible colour schemes to pick from, each with their own custom hover colours.",
				string: `<Button color="primary" startIcon={<FaHeart />}>Default</Button>`,
				component: (
					<ExampleButton color="primary" startIcon={<FaHeart />}>
						Default
					</ExampleButton>
				),
			},
		],
	},
];

export default components;
